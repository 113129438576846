import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import UsersView from "../views/UsersView.vue";
import CampaignsView from "@/views/campaigns/CampaignsView";
import CampaignLogin from "@/views/campaigns/CampaignLogin";
import CampaignDashboard from "@/views/campaigns/CampaignDashboard";
import CampaignNotFound from "@/views/campaigns/CampaignNotFound";
import store from "../store/index";

Vue.use(VueRouter);

const campaignGuard = async (to, from, next) => {
	try {
		// await store.dispatch("activeCampaign/check", to.params.campaign);
		try {
			//if cid is ok, and user logged in
			await store.dispatch("user/checkLogin", to.params.campaign);
			if (to.name === "CampaignDashboard") return next();
			return next(`/${to.params.campaign}/dashboard`);
		} catch (e) {
			//if cid is ok, but user not logged in
			if (to.name === "CampaignDashboard")
				return next(`/${to.params.campaign}`);
			else if (to.name === "CampaignLogin") return next();
		}
	} catch (e) {
		//if cid is not ok
		next("/nocampaign");
	}
};

const adminGuard = async (to, from, next) => {
	try {
		await store.dispatch("admin/checkLogin");
		store.dispatch("fonts/google/get");
		return to.name === "AdminLogin" ? next({ name: "CampaignsView" }) : next();
	} catch (e) {
		return to.name === "AdminLogin" ? next() : next({ name: "AdminLogin" });
	}
};

const routes = [
	//Admin
	{
		path: "/admin",
		name: "AdminLogin",
		component: Home,
		beforeEnter: adminGuard,
	},
	{
		path: "/admin/campaigns",
		name: "CampaignsView",
		component: CampaignsView,
		beforeEnter: adminGuard,
	},
	{
		path: "/admin/users",
		name: "UsersView",
		component: UsersView,
		beforeEnter: adminGuard,
	},

	//Campaigns
	{
		path: "/nocampaign",
		name: "CampaignNotFound",
		component: CampaignNotFound,
	},
	{
		path: "/:campaign",
		name: "CampaignLogin",
		beforeEnter: campaignGuard,
		component: CampaignLogin,
	},
	{
		path: "/:campaign/dashboard",
		beforeEnter: campaignGuard,
		name: "CampaignDashboard",
		component: CampaignDashboard,
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
