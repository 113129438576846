import axios from "axios";
import router from "@/router/index";

export default {
	state: {
		data: [],
	},
	getters: {
		users: state => state.data,
	},
	mutations: {
		"users/set": (state, payload) => (state.data = payload),
		"user/remove": (state, userId) =>
			(state.data = state.data.filter(user => user._id !== userId)),
	},
	actions: {
		"users/get": async context => {
			let { data } = await axios.get("/api/admin/getUsers");
			context.commit("users/set", data);
		},
		"users/remove": async (context, userId) => {
			await axios.delete(`/api/user/${userId}`);
			context.commit("user/remove");
		},
	},
};
