import axios from "axios";

export default {
	state: {
		data: [],
		campaign: null,
	},
	getters: {
		campaigns: state => state.data,
	},
	mutations: {
		setCampaigns: (state, payload) => (state.data = payload),
		setCampaign: (state, payload) => (state.campaign = payload),
		addCampaign: (state, payload) => state.data.push(payload),
		removeCampaign: (state, id) =>
			(state.data = state.data.filter(item => {
				return item._id !== id;
			})),
		updateCampaign: async (state, payload) => {
			state.data = state.data.map(item => {
				if (item._id === payload._id) {
					return payload;
				}
				return item;
			});
		},
	},
	actions: {
		"campaigns/get": async context => {
			try {
				const { data } = await axios.get("/api/campaigns");
				context.commit("setCampaigns", data);
			} catch (e) {
				console.log(e);
			}
		},
		"campaign/show": async (context, id) => {
			try {
				let { data } = await axios.get(`/api/campaigns/${id}`);
				context.commit("setCampaign", data);
			} catch (e) {
				console.log(e);
			}
		},
		"campaigns/insert": async (context, payload) => {
			try {
				const formData = new FormData();
				if (payload.groups) payload.groups = JSON.stringify(payload.groups);
				if (payload.font) payload.font = JSON.stringify(payload.font);
				for (var key in payload) formData.append(key, payload[key]);
				await axios.post(`/api/campaigns`, formData);
				await context.dispatch("campaigns/get");
			} catch (e) {
				console.log(e);
			}
		},
		"campaigns/delete": async (context, id) => {
			try {
				await axios.delete(`/api/campaigns/${id}`);
				context.commit("removeCampaign", id);
			} catch (e) {
				console.log(e);
			}
		},
		"campaign/update": async (context, data) => {
			try {
				const formData = new FormData();
				if (data.groups) data.groups = JSON.stringify(data.groups);
				if (data.font) data.font = JSON.stringify(data.font);
				for (var key in data) formData.append(key, data[key]);
				await axios.put(`/api/campaigns/${data._id}`, formData);
				await context.dispatch("campaigns/get");
			} catch (e) {
				console.log(e);
			}
		},
		"campaign/replicate": async (context, { id, cid }) => {
			try {
				const { data } = await axios.post(`/api/campaigns/replicate/${id}`, {
					cid,
				});
				await context.dispatch("campaigns/get");
				await context.dispatch("games/get");
			} catch (e) {
				console.log(e);
			}
		},
	},
};
