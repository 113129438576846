<template>
  <v-dialog v-model="openModal" width="290">
    <v-card>
      <v-date-picker
          v-if="step === 1"
          v-model="date"
          color="primary"
          @change="nextStep"
      ></v-date-picker>
      <v-time-picker
          v-else
          v-model="time"
          @change="nextStep"
          format="24hr"
      ></v-time-picker>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "TimePicker",
  props: {value: Boolean, timeToUpdate: String},
  data() {
    return {
      step: 1,
      date: null,
      time: null
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    openModal() {
      this.clean()
    },
  },
  methods: {
    nextStep() {
      if (this.step >= 2)
        this.sendFullDate()
      else
        this.step++
    },
    sendFullDate() {
      this.$emit("updateTime", {
        timeToUpdate: this.timeToUpdate,
        newTime: dayjs.tz(this.date + "T" + this.time, "Asia/Jerusalem")
      });
      this.openModal = false
    },
    clean() {
      this.step = 1
      this.date = null
      this.time = null
    }
  }
}
</script>

<style scoped>

</style>