<template>
	<page class="page d-flex justify-center align-center">
		<h1>קמפיין לא קיים</h1>
	</page>
</template>
<script>
export default {
	name: "",
	data() {
		return {};
	},
	props: {},
	components: {},
	methods: {},
	mounted: {},
};
</script>
<style lang="scss" scoped>
.page {
	height: 90vh;
	color: var(--primary);
}
</style>
