<template>
	<v-card>
		<v-card-title>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				label="חיפוש"
				single-line
				hide-details
			></v-text-field>
		</v-card-title>
		<v-data-table
			:headers="headers"
			:items="items"
			:search="search"
			:loading="isLoading"
		>
			<template #item.campaigns="{item}">
				<v-chip
					color="primary"
					v-for="campaign in item.campaigns"
					:key="campaign.cid"
					v-text="`${campaign.cid}`"
				/>
			</template>
			<template #item.actions="{item}">
				<v-icon @click="deleteUser(item)" color="red">mdi-delete</v-icon>
			</template>
		</v-data-table>

		<!-- delete user dialog -->
		<v-dialog v-model="deleteUserDialog" width="400">
			<v-card
				class="d-flex flex-column justify-center align-center text-center"
				height="150"
			>
				<div>למחוק משתמש לצמיתות?</div>
				<v-btn class="error mt-4" @click="deleteUserFinal">מחק</v-btn>
			</v-card>
		</v-dialog>
	</v-card>
</template>
<script>
export default {
	name: "UsersTable",
	props: { users: Array },
	data() {
		return {
			search: "",
			singleExpand: true,
			dialog: false,
			items: [],
			headers: [
				{
					text: "שם",
					align: "start",
					filterable: true,
					value: "username",
				},
				{ text: "טלפון", value: "phone" },
				{ text: "אימייל", value: "email" },
				{ text: "ניקוד קמפיין", value: "cScore" },
				{ text: "קמפיינים", value: "campaigns" },
				{ text: "פעולות", value: "actions" },
			],
			selectedFriendLink: null,
			selectedUser: null,
			deleteUserDialog: false,
			rules: {
				isNum: v => this.isNum(v) || "מספרים בלבד",
			},
			isLoading: false,
		};
	},
	watch: {
		users(v) {
			this.items = v;
		},
	},
	async mounted() {
		this.isLoading = true;
		await this.$store.dispatch("users/get");
		this.isLoading = false;
	},
	methods: {
		toggleDeleteUserDialog(item) {
			this.selectedUser = item;
			this.deleteUserDialog = true;
		},
		deleteUser(user) {
			this.selectedUser = user;
			this.deleteUserDialog = true;
		},
		async deleteUserFinal() {
			await this.$store.dispatch("users/remove", this.selectedUser._id);
			await this.$store.dispatch("users/get");
			this.deleteUserDialog = false;
		},
		isNum(v) {
			var reg = /^\d+$/;
			return reg.test(v);
		},
	},
};
</script>
