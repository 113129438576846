<template>
	<v-dialog v-model="openModal" width="600">
		<v-card
			class="d-flex flex-column justify-center align-center px-8 py-8"
			id="winners"
		>
			<v-card-title class="d-flex justify-center align-center flex-column">
				<v-img max-width="70" src="/images/trophy.png" />
				<h3>טבלת מובילים</h3>
			</v-card-title>
			<v-card-text>
				<v-tabs
					v-model="tab"
					background-color="campaign.backgroundColor"
					centered
				>
					<v-tab href="#tab-1">שחקנים</v-tab>
					<v-tab href="#tab-2" :disabled="!campaign.isGroup">קבוצות</v-tab>
					<v-tabs-items v-model="tab">
						<v-tab-item value="tab-1">
							<v-data-table
								:headers="winnersHeaders"
								:items="winners"
								:search="searchWinners"
								:loading="isLoading"
								:hide-default-footer="true"
								:items-per-page="Number('-1')"
							/>
						</v-tab-item>
						<v-tab-item value="tab-2">
							<v-data-table
								:headers="groupWinnersHeaders"
								:items="groupWinners"
								:search="searchGroupWinners"
								:loading="isLoading"
								:hide-default-footer="true"
								:items-per-page="Number('-1')"
							/>
						</v-tab-item>
					</v-tabs-items>
				</v-tabs>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "Winners",
	props: {
		value: Boolean,
		cid: String,
	},
	data() {
		return {
			searchWinners: "",
			searchGroupWinners: "",
			isLoading: false,
			tab: null,
			groupWinnersHeaders: [
				{ text: "קבוצה", value: "name", align: "center" },
				{ text: "ניקוד", value: "score" },
			],
		};
	},
	computed: {
		winners() {
			return this.$store.state.activeCampaign?.winners &&
				this.$store.state.activeCampaign.winners.length > 0
				? this.$store.state.activeCampaign.winners
				: [];
		},
		groupWinners() {
			return this.$store.getters.groupWinners;
		},
		winnersHeaders() {
			let headers = [
				{ text: "שחקן", value: "username", align: "center" },
				{ text: "ניקוד", value: "cScore" },
			];
			if (this.$store.getters.isGroup) {
				headers.push({ text: "קבוצה", value: "cGroup" });
			}
			return headers;
		},
		campaign() {
			return this.$store.getters.campaign;
		},

		openModal: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
	},
	watch: {
		async openModal(val) {
			if (val) {
				await this.$store.dispatch(
					"activeCampaign/winners",
					this.$store.state.activeCampaign.data.cid
				);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
#winners {
	font-family: var(--font) !important;
}
</style>
