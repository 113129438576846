import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";
import "@babel/polyfill";
import "@mdi/font/css/materialdesignicons.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "@/styles/main.scss";

Vue.config.productionTip = false;
Vue.use(VueSweetalert2, {
	confirmButtonColor: "#ee44aa",
	cancelButtonColor: "#c4c4c4",
	cancelButtonText: "ביטול",
	confirmButtonText: "אישור",
});

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
}).$mount("#app");
