<template>
	<div class="game-thumb" ref="gameThumb">
		<h3 class="text-center">
			<span>{{ game.maxScore }}</span>
			<span> נקודות </span>
		</h3>
		<v-icon
			v-if="game.instructions && game.instructions !== ''"
			color="black"
			class="info-icon"
			@click="instructionsDialog = true"
			>mdi-information</v-icon
		>
		<div
			class="thumb d-flex flex-column justify-center align-center"
			@click="handleOpenGame"
		>
			<v-img
				:src="imgSrc"
				width="80px"
				@error="defaultImage"
				lazy-src="/images/picture.png"
			/>
			<div v-if="game.visibleName" class="text-center mt-1">
				{{ game.name }}
			</div>
		</div>
		<v-dialog v-model="instructionsDialog" max-width="400">
			<v-card
				class="text-center d-flex justify-center align-center flex-column"
			>
				<v-card-title>
					<h3 class="text-center">הוראות משחק</h3>
				</v-card-title>
				<v-card-text v-html="game.instructions" />
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import actions from "@/actions";

export default {
	name: "GameThumb",
	props: { campaign: Object, game: Object },
	data() {
		return {
			instructionsDialog: false,
			endCode: null,
			accessCode: null,
			hover: false,
		};
	},
	computed: {
		imgSrc() {
			if (!this.game.isActive) return "/images/lock.png";
			if (this.game.isPlayed) return "/images/checked.png";
			return this.game.imagePath
				? `/uploads/games/${this.game.imagePath}`
				: "/images/game-console.png";
		},
	},
	methods: {
		async handleOpenGame() {
			if (!this.game.isActive)
				return this.$emit("invokeError", "המשחק לא פעיל");
			if (this.game.isLinear) {
				let isLinear = await this.checkLinear();
				if (!isLinear.check) return this.$emit("invokeError", isLinear.error);
			}
			if (this.game.isTime) {
				let isTime = await this.checkTime();
				if (!isTime.check) return this.$emit("invokeError", isTime.error);
			}
			if (this.game.isRestricted) {
				return this.checkAccessCode();
			}
			return this.openGame();
		},
		async checkAccessCode() {
			this.$swal(
				actions.createInputSwaldata(
					"נא להזין קוד פתיחת משחק",
					this.campaign.mainColor
				)
			).then(async result => {
				try {
					await this.$store.dispatch("activeCampaign/checkAccessCode", {
						gameId: this.game._id,
						accessCode: result.value,
					});
					this.clean();
					this.openGame();
				} catch (e) {
					actions.createSwal("error", e, this.campaign.mainColor);
				}
			});
		},
		async checkLinear() {
			try {
				await this.$store.dispatch("activeCampaign/checkLinear", {
					gameId: this.game._id,
				});
				return { check: true };
			} catch (e) {
				actions.createSwal("error", e, this.campaign.mainColor);
			}
		},
		async checkTime() {
			let { data } = await axios.post(
				`/api/game/${this.campaign._id}/checkTime`,
				{
					gameId: this.game._id,
				}
			);
			if (data.active) return { check: true };
			else
				return {
					check: false,
					error: `המשחק יתחיל ב  ${dayjs(data.start).format(
						"DD.MM שעה HH:mm"
					)}`,
				};
		},
		openGame() {
			if (this.game.isIframe) {
				this.$emit("openGameIframe", {
					link: this.game.link,
					game: this.game,
				});
			} else {
				window.open(this.game.link, "_blank");
				this.$emit("endGame", { id: this.game._id, msg: this.game.winMsg });
			}
		},
		clean() {
			this.endCode = null;
			this.error = null;
		},
		defaultImage() {
			this.imgSrc = "/images/game-console.png";
		},
	},
	watch: {
		endCodeDialog(status) {
			if (!status) {
				this.clean();
			}
		},
	},
};
</script>

<style lang="scss">
@import "../../styles/main.scss";
.info-icon {
	position: absolute !important;
	margin-right: 18px;
	margin-top: 10px;
	z-index: 1;
	@include responsive($tablet) {
		margin-right: 0px;
	}
}
.thumb {
	width: 150px;
	border-radius: 10px;
	padding: 10px;
	cursor: pointer;
	margin-top: -7px;
	@include responsive($tablet) {
		width: 110px;
	}
}
</style>
