<template>
	<v-container class="campaigns">
		<campaign-card
			v-for="campaign in campaigns"
			:key="campaign._id"
			:campaign="campaign"
			@showCampaignHandler="showCampaignHandler"
		/>
		<campaign-handler
			v-model="campaignHandlerDialog"
			@closeCampaignHandler="closeCampaignHandler"
			:isNew="false"
			:chosenCampaign="chosenCampaign"
		/>
	</v-container>
</template>

<script>
import CampaignCard from "@/components/campaigns/CampaignCard";
import CampaignHandler from "@/components/campaigns/CampaignHandler";

export default {
	name: "CampaignsContainer",
	data() {
		return {
			chosenCampaign: {},
			campaignHandlerDialog: false,
		};
	},
	components: { CampaignCard, CampaignHandler },
	computed: {
		campaigns() {
			return this.$store.state.campaigns.data;
		},
	},
	methods: {
		showCampaignHandler(campaign) {
			this.chosenCampaign = campaign;
			this.$store.commit("setCampaign", campaign);
			this.$store.commit("setCampaignGames", campaign._id);
			this.campaignHandlerDialog = true;
		},
		closeCampaignHandler() {
			this.campaignHandlerDialog = false;
			this.chosenCampaign = {};
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.campaigns {
	width: 1500px;
	display: grid;
	grid-template-columns: auto auto;
}
</style>
