<template>
	<v-dialog v-model="openModal" max-width="500">
		<v-card>
			<v-card-title>
				<v-text-field
					v-model="form.name"
					hint="השם משתמש ישמש גם ככותרת לגוגל"
				/>
				<v-switch v-model="form.visibleName" label="יוצג ככותרת" />
			</v-card-title>
			<v-card-text>
				<v-textarea
					label="תיאור"
					v-model="form.description"
					hint="התיאור ישמש גם לתיאור בגוגל"
				/>
				<v-textarea
					label="הוראות משחק"
					v-model="form.instructions"
					hint="אפשר להזין טקסט או להזריק html במקום"
				/>
				<div class="d-flex justify-space-between">
					<v-switch v-model="form.isActive" label="פעיל" input-value="true" />
					<v-switch v-model="form.isRestricted" label="מוגבל בקוד" />
					<v-switch v-model="form.isLinear" label="לינארי" />
					<v-switch v-model="form.isTime" label="זמן" />
				</div>
				<v-text-field
					v-model="form.accessCode"
					label="קוד פתיחת משחק"
					v-if="form.isRestricted"
					hint="המשתמש יכול לשחק במשחק רק אחרי שהזין קוד זה"
				/>
				<v-select
					v-if="form.isLinear"
					v-model="form.parent"
					:items="this.parents"
					name="name"
					item-text="name"
					label="מי האבא"
					:item-value="'_id'"
					hint="המשתמש לא יכול לשחק במשחק זה לפני שסיים לשחק במחשק אבא"
				/>
				<v-text-field v-model="form.maxScore" type="number" label="ניקוד" />
				<v-text-field v-model="form.link" type="string" label="קישור ללקוח" />
				<v-switch v-model="form.isIframe" label="נפתח ב iframe ?" />
				<v-text-field
					v-model="form.endCode"
					type="string"
					label="קוד סיום משחק"
					hint="המשתמש צריך להזין קוד זה בשביל לסיים את המשחק"
				/>
				<v-text-field
					v-if="form.isTime"
					v-model="form.start"
					label="זמן התחלה"
					prepend-icon="mdi-calendar"
					readonly
					hint="לפני זמן זה לא יהיה אפשר לשחק במשחק"
					@click="startTimePickerDialog = true"
				/>
				<v-text-field
					v-if="form.isTime"
					v-model="form.end"
					value="hes"
					label="זמן סיום"
					prepend-icon="mdi-calendar"
					readonly
					@click="endTimePickerDialog = true"
					hint="אחרי זמן זה לא יהיה אפשר לשחק במשחק"
				/>
				<div>
					<label v-if="!this.isNew">אייקון</label>
					<v-icon v-if="imgSrc" color="red" @click="removeImage"
						>mdi-delete</v-icon
					>
					<img
						:src="imgSrc"
						width="100"
						style="margin: 0 auto"
						v-if="!isNew && imgSrc"
					/>
					<v-file-input
						accept="image/png, image/jpeg, image/gif"
						:label="isNew ? 'העלת אייקון חדש' : 'עדכון אייקון משחק'"
						prepend-icon="mdi-camera"
						v-model="form.image"
						@change="form.updateImage = true"
						:rules="imageUploadRules"
					/>
				</div>
				<v-text-field
					v-model="form.winMsg"
					label="תגובה לסיום המשחק"
					hint="איזו הודעה המשתמש יראה כאשר סיים את המשחק בהצלחה"
				/>
			</v-card-text>
			<v-card-actions class="d-flex justify-center">
				<v-btn v-if="isNew" class="primary" @click="create">
					<v-icon>mdi-add</v-icon>
					יצירה
				</v-btn>
				<v-btn v-else class="primary" @click="update">
					<v-icon>mdi-add</v-icon>
					עדכון
				</v-btn>
			</v-card-actions>
		</v-card>
		<time-picker
			v-model="startTimePickerDialog"
			:timeToUpdate="'start'"
			@updateTime="updateTime"
		/>
		<time-picker
			v-model="endTimePickerDialog"
			:timeToUpdate="'end'"
			@updateTime="updateTime"
		/>
		<v-snackbar
			class=" d-flex justify-center text-center"
			v-model="snackbar"
			color="primary"
		>
			<div class="text-center">{{ errorMsg }}</div>
		</v-snackbar>
	</v-dialog>
</template>
<script>
import TimePicker from "./TimePicker";

export default {
	name: "GameClassHandler",
	props: { value: Boolean, isNew: Boolean, campaign: Object },
	components: { TimePicker },
	data() {
		return {
			snackbar: false,
			errorMsg: null,
			startTimePickerDialog: false,
			endTimePickerDialog: false,
			imgSrc: null,
			imageUploadRules: [
				value => !value || value.size < 2000000 || "גודל מקסימלי של 2 מגה",
			],
			form: {
				visibleName: true,
				isActive: true,
			},
		};
	},
	watch: {
		form(newVal) {
			if (newVal.imagePath) this.imgSrc = `/uploads/games/${newVal.imagePath}`;
		},
		"form.maxScore"(newV) {
			this.form.maxScore = Number(newV);
		},
		openModal(val) {
			if (!val || this.isNew) this.clean();
			else {
				this.form = { ...this.$store.state.games.game };
			}
		},
	},
	computed: {
		openModal: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		parents() {
			if (this.isNew) return this.$store.state.games.campaignGames;
			return this.$store.state.games.campaignGames.filter(item => {
				return item._id !== this.$store.state.games.game._id;
			});
		},
	},
	methods: {
		updateTime({ timeToUpdate, newTime }) {
			if (timeToUpdate === "start") {
				this.form.start = newTime.format();
			} else {
				this.form.end = newTime.format();
			}
		},
		async create() {
			if (this.validateForm()) {
				await this.$store.dispatch("games/insert", {
					...this.form,
					campaignId: this.campaign._id,
				});
				this.$store.commit("setCampaignGames", this.campaign._id);
				this.clean();
				this.openModal = false;
			}
		},
		async update() {
			if (!this.validateForm()) return;
			await this.$store.dispatch("game/update", {
				...this.form,
				campaignId: this.campaign._id,
			});
			this.$store.commit("setCampaignGames", this.campaign._id);
			this.clean();
			this.openModal = false;
		},
		async delete() {
			await this.$store.dispatch("games/delete", {
				id: this.form._id,
			});
		},
		clean() {
			this.form = {
				visibleName: true,
				isActive: true,
			};
		},
		logError(msg) {
			this.errorMsg = msg;
			this.snackbar = true;
		},
		validateForm() {
			if (
				!(
					this.form.name &&
					this.form.maxScore &&
					this.form.link &&
					this.form.endCode
				)
			) {
				this.logError("נא למלא את כל השדות");
				return false;
			}
			if (
				this.form.isRestricted &&
				(this.form.isActive === null || !this.form.accessCode)
			) {
				this.logError("נא להזין קוד פתיחת משחק");
				return false;
			}

			if (this.form.isLinear && !this.form.parent) {
				this.logError("נא לבחור את ההורה של המשחק הלינארי");
				return false;
			}

			if (this.form.isTime && (!this.form.start || !this.form.end)) {
				this.logError("נא לבחור זמן התחלה וסוף");
				return false;
			}
			return true;
		},
		removeImage() {
			this.form.image = null;
			this.imgSrc = null;
			this.form.updateImage = true;
		},
	},
};
</script>

<style scoped></style>
