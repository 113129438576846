import Vue from "vue";
import Vuex from "vuex";
import adminModule from "@/store/modules/adminModule";
import campaignsModule from "@/store/modules/campaignsModule";
import gamesModule from "@/store/modules/gamesModule";
import userModule from "./modules/userModule";
import usersModule from "./modules/usersModule";
import winnersModule from "@/store/modules/winnersModule";
import activeCampaignModule from "@/store/modules/activeCampaignModule";
import fontsModule from "@/store/modules/fontsModule";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		admin: adminModule,
		campaigns: campaignsModule,
		games: gamesModule,
		user: userModule,
		users: usersModule,
		winners: winnersModule,
		activeCampaign: activeCampaignModule,
		fontsModule,
	},
});
