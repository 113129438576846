import axios from "axios";

export default {
	state: {
		fonts: [],
		google: [],
		font: {},
	},
	getters: {
		fonts: state => state.fonts,
		google: state => state.google,
		font: state => state.font,
	},
	mutations: {
		"fonts/set": (state, payload) => (state.fonts = payload),
		"font/set": (state, payload) => (state.font = payload),
		"fonts/google/set": (state, payload) => (state.google = payload),
		"fonts/add": (state, payload) => state.fonts.push(payload),
		"fonts/remove": (state, id) =>
			(state.fonts = state.fonts.filter(item => {
				return item._id !== id;
			})),
		"fonts/update": (state, payload) => {
			state.fonts = state.fonts.map(item => {
				if (item._id === payload._id) {
					return payload;
				}
				return item;
			});
		},
	},
	actions: {
		"fonts/get": async context => {
			try {
				const { data } = await axios.get("/api/fonts");
				context.commit("fonts/set", data);
			} catch (e) {
				console.log(e);
			}
		},
		"fonts/google/get": async context => {
			try {
				const { data } = await axios.get("/api/fonts/google");
				context.commit("fonts/google/set", data.items);
			} catch (e) {
				console.log(e);
			}
		},
		"font/show": async (context, id) => {
			try {
				let { data } = await axios.get(`/api/fonts/id`);
				context.commit("font/set", data);
			} catch (e) {
				console.log(e);
			}
		},
		"fonts/insert": async (context, data) => {
			try {
				const formData = new FormData();
				for (var key in data) {
					formData.append(key, data[key]);
				}
				let { data } = await axios.post(`/api/fonts`, formData);
				context.commit("fonts/add", data);
			} catch (e) {
				console.log(e);
			}
		},
		"fonts/remove": async (context, id) => {
			try {
				await axios.delete(`/api/fonts/id`);
				context.commit("fonts/remove", id);
			} catch (e) {
				console.log(e);
			}
		},
		"fonts/update": async (context, payload) => {
			try {
				const formData = new FormData();
				for (var key in payload) {
					formData.append(key, payload[key]);
				}
				await axios.put(`/api/fonts/${payload._id}`, formData);
				await context.dispatch("fonts/get");
			} catch (e) {
				console.log(e);
			}
		},
	},
};
