import axios from "axios";

export default {
	state: {
		data: [],
		campaignGames: [],
		game: null,
	},
	getters: {
		allGames: state => state.data,
		campaignGames: state => state.campaignGames,
	},
	mutations: {
		setGames: (state, payload) => (state.data = payload),
		addGame: (state, payload) => state.data.push(payload),
		"game/remove": (state, id) => {
			state.data = state.data.filter(item => item._id !== id);
			state.campaignGames = state.campaignGames.filter(item => item._id !== id);
		},
		"game/update": (state, payload) => {
			state.data = state.data.map(item => {
				if (item._id === payload._id) {
					return payload;
				}
				return item;
			});
		},
		setCampaignGames: (state, campaignId) => {
			state.campaignGames = state.data.filter(
				item => item.campaignId === campaignId
			);
		},
		setGame: (state, game) => {
			state.game = game;
		},
	},
	actions: {
		"games/get": async context => {
			try {
				const { data } = await axios.get("/api/gameClass");
				context.commit("setGames", data);
			} catch (e) {
				console.log(e);
			}
		},
		"games/insert": async (context, data) => {
			try {
				const formData = new FormData();
				for (var key in data) {
					formData.append(key, data[key]);
				}
				const res = await axios.post(`/api/gameClass`, formData);
				context.commit("addGame", res.data);
			} catch (e) {
				console.log(e);
			}
		},
		"games/delete": async (context, id) => {
			try {
				await axios.delete(`/api/gameClass/${id}`);
				context.commit("game/remove", id);
				await context.dispatch("games/get");
			} catch (e) {
				console.log(e);
			}
		},
		"game/update": async (context, payload) => {
			try {
				let data = new FormData();
				for (var key in payload) {
					data.append(key, payload[key]);
				}
				const res = await axios.put(`/api/gameClass/${payload._id}`, data);
				await context.dispatch("games/get");
			} catch (e) {
				console.log(e);
			}
		},
	},
};
