<template>
  <v-toolbar
      v-if="/^\/admin\//i.test($route.path) && $route.name !== 'AdminLogin'"
  >
    <nav class="router-navbar">
      <router-link to="/admin/campaigns">
        <span>קמפיינים</span>
        <span> | </span>
      </router-link>
      <router-link to="/admin/users">
        <span>משתמשים</span>
<!--        <span> | </span>-->
      </router-link>
<!--      <router-link to="/admin/winners">-->
      <!--        <span>טבלאות מנצחים</span>-->
      <!--        <span>  </span>-->
      <!--      </router-link>-->
    </nav>
    <v-spacer/>
    <v-btn @click="logout" class="error">
      <v-icon>mdi-account-arrow-right-outline</v-icon>
      התנתקות
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      selectedCampaign: null,
      campaigns: [],
    };
  },
  methods: {
    async logout() {
      this.$store.dispatch("admin/logout");
    },
  },
};
</script>

<!-- Not scoped! -->
<style lang="scss">
.router-navbar {
  a {
    color: black !important;
    text-decoration: none;
  }

  .router-link-active {
    color: #ee44aa !important;
  }
}
</style>
