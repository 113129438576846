import axios from "axios";
import router from "../../router/index";
import actions from "../../actions.js";

export default {
	state: {
		adminLoggedIn: false,
		adminName: null,
	},
	getters: {
		adminName: state => state.adminName,
		adminLoggedIn: state => state.adminLoggedIn,
	},
	mutations: {
		loadAdminName: (state, payload) => (state.adminName = payload),
		"admin/login": (state, admin) => {
			state.adminLoggedIn = true;
			state.adminName = admin.name;
		},
		clearData: state => {
			state.adminLoggedIn = false;
			state.adminName = null;
		},
	},
	actions: {
		"admin/login": async (context, payload) => {
			try {
				let { data } = await axios.post("/api/admin/login", payload);
				context.commit("admin/login", data);
			} catch (e) {
				actions.createSwal("error", e.response.data);
				throw new Error(e.response.data);
			}
		},
		"admin/checkLogin": async context => {
			try {
				let { data } = await axios.get("/api/admin/checkLogin");
				context.commit("admin/login", data);
			} catch (e) {
				throw e.response.data;
			}
		},
		"admin/logout": async context => {
			context.commit("clearData");
			await axios.post("/api/admin/logout");
			router.push({ name: "AdminLogin" });
		},
	},
};
