<template>
	<v-card :loading="loading" class="mx-5 my-5">
		<v-card-title>
			<span class="primary--text"> {{ campaign.name }} </span>
		</v-card-title>
		<v-card-subtitle>
			<div>
				<b><span>תיאור הקפמיין </span></b>
				<span>{{ campaign.description }}</span>
			</div>
			<div>
				<b><span>נוצר ב </span></b>
				<span>{{ formattedDate }}</span>
			</div>
			<div>
				<b><span>מזהה </span></b>
				<span>{{ campaign.cid }}</span>
			</div>
			<div>
				<b><span>לינק </span></b>
				<span class="primary--text link" @click="gotoCampaign">{{
					campaignLink
				}}</span>
			</div>
		</v-card-subtitle>
		<v-card-actions class="d-flex">
			<v-icon @click="editCampaign" class="primary--text">mdi-pencil</v-icon>
			<v-icon @click="replicate">mdi-folder-multiple-outline</v-icon>
			<v-spacer></v-spacer>
			<v-icon @click="deleteCampaign" class="mx-2 red--text">mdi-delete</v-icon>
		</v-card-actions>
	</v-card>
</template>

<script>
import dayjs from "dayjs";

export default {
	name: "CampaignCard",
	props: { campaign: Object },
	data() {
		return {
			campaignHandlerDialog: true,
			loading: false,
			formattedDate: dayjs(this.campaign.createdAt).format(
				"YY-MM-DD בשעה HH:mm"
			),
			formattedUpdateDate: dayjs(this.campaign.updatedAt).format(
				"YY-MM-DD בשעה HH:mm"
			),
			campaignLink:
				"http://" + window.location.hostname + "/" + this.campaign.cid,
			alertDialog: false,
		};
	},
	methods: {
		async deleteCampaign() {
			this.$swal({
				icon: "warning",
				title: '<p style="direction:rtl;">למחוק קמפיין לצמיתות?</p>',
				html: "<p>עם הקמפיין ימחקו כל המשתמשים וכל המשחקים הקשורים אליו</p>",
				showConfirmButton: true,
				showCancelButton: true,
				confirmButtonText: "אישור",
				cancelButtonText: "ביטול",
			}).then(async result => {
				if (result.isConfirmed) {
					this.loading = true;
					await this.$store.dispatch("campaigns/delete", this.campaign._id);
					this.loading = false;
				}
			});
		},
		editCampaign() {
			this.$emit("showCampaignHandler", this.campaign);
		},
		gotoCampaign() {
			window.open(this.campaignLink, "_blank");
		},
		replicate() {
			this.$swal({
				icon: "question",
				title: '<p style="direction:rtl;">לשכפל קמפיין?</p>',
				html: "<p>נא הזן מזהה קמפיין חדש</p>",
				input: "text",
				inputValidator: value => {
					if (!value) {
						return "חובה להזין מזהה חדש";
					}
					if (!/^[a-zA-Z]+$/.test(value)) {
						return "רק אותיות באנגלית וללא רווחים";
					}
					if (value === this.campaign.cid) {
						return "המזהה לא יכול להיות זהה למזהה של הקמפיין המשוכפל";
					}
				},
				showConfirmButton: true,
				confirmButtonText: "אישור",
			}).then(result => {
				this.$store.dispatch("campaign/replicate", {
					id: this.campaign._id,
					cid: result.value,
				});
			});
		},
	},
};
</script>

<style scoped>
.link {
	cursor: pointer;
}
</style>
