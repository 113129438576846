<template>
	<v-app>
		<v-main>
			<Navbar />
			<router-view />
		</v-main>
		<close-app-footer v-if="showFooter" />
	</v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
import CloseAppFooter from "./components/CloseAppFooter";

export default {
	name: "App",
	components: { Navbar, CloseAppFooter },
	computed: {
		showFooter() {
			return this.$route.path.includes("/admin");
		},
	},
};
</script>
