import axios from "axios";

export default {
    state: {
        data: [],
        currentCampaign: []
    },
    getters: {
        winnersTables: state => state.data,
    },
    mutations: {
        setTables: (state, payload) => (state.data = payload),
        addTable: (state, payload) => state.data.push(payload),
        removeTable: (state, id) =>
            (state.data = state.data.filter(item => {
                return item._id !== id;
            })),
        setCurrentCampaignWinners: (state, payload) => state.currentCampaign = payload
    },
    actions: {
        "winners/campaign/get": async (context, cid) => {
            try {
                const {data} = await axios.get("/api/campaigns/winners/" + cid);
                context.commit("setCurrentCampaignWinners", data)
            } catch (e) {
                console.log(e)
            }
        },
        "winners/get": async context => {
            try {
                const {data} = await axios.get("/api/winners");
                context.commit("setTables", data);
            } catch (e) {
                console.log(e);
            }
        },
        "winners/insert": async (context, data) => {
            try {
                const response = await axios.post(`/api/winners`, data);
                context.commit("addTable", response.ata);
            } catch (e) {
                console.log(e);
            }
        },
        "winners/delete": async (context, id) => {
            try {
                await axios.delete(`/api/winners/${id}`);
                context.commit("removeCampaign", id);
            } catch (e) {
                console.log(e);
            }
        },
    },
};
