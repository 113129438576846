<template id="dashboard">
	<v-container v-if="error">
		{{ error }}
	</v-container>
	<div v-else class="d-flex justify-center align-center frame">
		<div
			v-if="loading"
			class="d-flex justify-center align-center"
			style="height: 100vh"
		>
			<v-progress-circular :size="50" color="primary" indeterminate />
		</div>
		<v-card
			v-else
			:color="campaign.backgroundColor"
			min-height="100vh"
			width="600px"
			dark
			class="page"
			ref="dashboard"
		>
			<v-card-actions class="d-flex justify-space-between nav-menu" ref="nav">
				<div>
					<span> שלום </span>
					<span> {{ user.username }} </span>
				</div>
				<div>{{ userScore }}</div>
				<v-btn text :color="campaign.mainColor" @click="showWinners = true"
					>מי מוביל</v-btn
				>
				<div @click="logout" style="cursor: pointer">יציאה</div>
			</v-card-actions>
			<v-card-text
				class="body d-flex flex-column justify-center align-center"
				ref="body"
			>
				<v-img
					v-if="campaign.hasInfoIcon"
					:src="campaignInfoImage"
					class="infoIcon"
					@click="gotoInfo"
					width="100"
				/>
				<v-img :src="campaignImg" class="my-8" max-width="140" />

				<h2 v-if="campaign.visibleName" id="mainHeader" class="mb-8">
					{{ campaign.name }}
				</h2>
				<div class="d-flex flex-wrap justify-center">
					<GameThumb
						v-for="game in games"
						:key="game._id"
						:game="game"
						:campaign="campaign"
						@invokeError="snackBarError"
						@getCampaignData="getCampaignData"
						@openGameIframe="openGameIframe"
						@endGame="endGame"
						style="margin: 5px"
					/>
				</div>
			</v-card-text>
		</v-card>

		<Winners v-model="showWinners" />

		<v-dialog
			v-model="openModal"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
			scrollable
		>
			<v-card>
				<v-card-actions class="d-flex justify-end">
					<v-btn color="green" @click="closeGameDialog">סיום משחק</v-btn>
				</v-card-actions>
				<v-card-text>
					<iframe
						:src="iframeGameSource"
						frameborder="0"
						style="height:100%; width: 100%;"
					></iframe>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-snackbar
			v-model="snackBar"
			class="d-flex justify-center"
			:color="campaign.mainColor"
			top
		>
			<div class="text-center">{{ snackBarTxt }}</div>
		</v-snackbar>
	</div>
</template>
<script>
import GameThumb from "@/components/campaigns/GameThumb";
import Winners from "@/components/campaigns/Winners";
import actions from "@/actions";

export default {
	name: "CampaignDashboard",
	components: { GameThumb, Winners },
	data() {
		return {
			loading: false,
			showWinners: false,
			error: null,
			snackBar: null,
			snackBarTxt: null,
			openModal: false,
			iframeGameSource: null,
			chosenGame: null,
		};
	},
	computed: {
		user() {
			return this.$store.getters.user || {};
		},
		campaign() {
			return this.$store.state.activeCampaign.data || {};
		},
		userScore() {
			let userC = this.user.campaigns.find(c => c.cid === this.campaign.cid);
			return userC ? userC.score : 0;
		},
		campaignImg() {
			return this.campaign.imagePath
				? `/uploads/campaigns/${this.campaign.imagePath}`
				: "/images/house.png" || "";
		},
		campaignBGImage() {
			return this.campaign.backgroundImagePath
				? `/uploads/campaigns/${this.campaign.backgroundImagePath}`
				: "/images/house.png" || "";
		},
		campaignInfoImage() {
			return this.campaign.infoIconPath
				? `/uploads/campaigns/${this.campaign.infoIconPath}`
				: null;
		},
		games() {
			return this.$store.state.activeCampaign.games || [];
		},
	},
	methods: {
		snackBarError(txt) {
			this.snackBar = true;
			this.snackBarTxt = txt;
		},
		async logout() {
			await this.$store.dispatch("user/logout", this.$route.params.campaign);
		},
		async getCampaignData() {
			try {
				await this.$store.dispatch(
					"activeCampaign/get",
					this.$route.params.campaign
				);
			} catch (e) {
				console.log(e);
			}
		},
		async openGameIframe({ game, link }) {
			this.chosenGame = game;
			this.iframeGameSource = link;
			this.openModal = true;
			console.log(this.iframeGameSource);
		},
		async closeGameDialog() {
			this.openModal = false;
			this.endGame({ id: this.chosenGame._id, msg: this.chosenGame.winMsg });
		},
		async endGame({ id, msg }) {
			console.log(id, msg);
			this.$swal(
				actions.createInputSwaldata(
					"נא להזין קוד סיום משחק",
					this.campaign.mainColor
				)
			).then(async result => {
				if (result.isConfirmed) {
					try {
						await this.$store.dispatch("activeCampaign/endGame", {
							endCode: result.value,
							gameId: id,
						});
						actions.createSwal("success", msg, this.campaign.mainColor);
					} catch (e) {
						actions.createSwal("error", e, this.campaign.mainColor);
					}
				}
			});
		},
		createFont() {
			const newStyle = document.createElement("style");
			newStyle.appendChild(
				document.createTextNode(
					"\
@font-face {\
    font-family: " +
						this.campaign.font.family +
						";\
    src: url('" +
						this.campaign.font.url +
						"')\
}\
"
				)
			);
			document.head.appendChild(newStyle);
		},

		gotoInfo() {
			window.open(this.campaign.infoLink, "_blank");
		},
	},
	async mounted() {
		try {
			this.loading = true;
			await this.getCampaignData();
			actions.setCampaignMeta(this.campaign);
			document.documentElement.style.setProperty(
				"--font",
				this.campaign.font.family
			);
			//setting css colors
			document.documentElement.style.setProperty(
				"--main-color",
				this.campaign.mainColor
			);
			document.documentElement.style.setProperty(
				"--background-color",
				this.campaign.backgroundColor
			);
			document.documentElement.style.setProperty(
				"--frame-color",
				this.campaign.frameColor
			);
			document.documentElement.style.setProperty(
				"--menu-color",
				this.campaign.menuColor
			);
			let dashboard = this.$refs.dashboard;
			if (this.campaign.backgroundImagePath) {
				dashboard.$el.style.backgroundImage = `url('${this.campaignBGImage}')`;
			}
			this.createFont();
			this.loading = false;
		} catch (e) {
			this.loading = false;
		}
	},
};
</script>
<style lang="scss" scoped>
.infoIcon {
	width: 40px;
	position: absolute;
	top: 80px;
	left: 50px;
	cursor: pointer;
}
.frame {
	background-color: var(--frame-color);
	.page {
		font-family: var(--font) !important;
		background-color: var(--background-color);
		background-size: cover;
		background-position: center center;
		color: var(--main-color) !important;
		.nav-menu {
			background-color: var(--menu-color);
		}
		.body {
			color: var(--main-color) !important;
		}
	}
}
</style>
