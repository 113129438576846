<template>
	<v-container v-if="errorMsg">
		<h2>{{ errorMsg }}</h2>
	</v-container>
	<v-container v-else>
		<div
			v-if="loading"
			class="d-flex justify-center align-center"
			style="height: 100vh"
		>
			<v-progress-circular :size="50" color="primary" indeterminate />
		</div>
		<div v-else>
			<h1 class="text-center">
				<span> התחברות </span>
				<span> {{ $store.getters.campaign.name }} </span>
			</h1>
			<v-row justify="center">
				<v-col cols="4">
					<v-form @keyup.enter="submit">
						<v-select
							:items="$store.getters.groups"
							v-model="group"
							label="בחירת קבוצה"
							v-if="$store.getters.isGroup"
						/>
						<v-text-field
							v-model="username"
							label="שם משתמש"
							required
						></v-text-field>
						<v-text-field
							v-model="phone"
							label="טלפון"
							:rules="[rules.required, rules.min]"
							required
						/>
						<v-row class="justify-center">
							<v-btn @click="submit">התחבר</v-btn>
						</v-row>
					</v-form>
				</v-col>
			</v-row>
			<v-alert
				v-if="isAlertMessage"
				outlined
				type="error"
				class="d-flex justify-center mt-5"
				>{{ this.error }}
			</v-alert>
			<div class="progress">
				<v-progress-circular
					:size="70"
					:width="7"
					color="primary"
					indeterminate
					v-if="loading"
				></v-progress-circular>
			</div>
		</div>
	</v-container>
</template>
<script>
import { createSwal, setCampaignMeta } from "../../actions";
export default {
	name: "CampaignLogin",
	data() {
		return {
			errorMsg: null,
			show: false,
			username: "",
			phone: "",
			group: null,
			error: "הפרטים לא נכונים",
			rules: {
				required: value => !!value || "נא למלא",
				min: v => v.length >= 8 || "לפחות 8 תווים",
				isValidForm: () => !(this.username && this.username.length > 0),
			},
			isAlertMessage: false,
			loading: false,
		};
	},
	methods: {
		validate() {
			if (!this.username) {
				createSwal("error", "נא להזין שם משתמש");
				return false;
			} else if (!this.phone) {
				createSwal("error", "נא להזין מספר טלפון");
				return false;
			} else if (this.$store.getters.isGroup && !this.group) {
				createSwal("error", "נא לבחור קבוצה");
				return false;
			} else {
				return true;
			}
		},
		async submit() {
			if (!this.validate()) return;
			try {
				let data = {
					cid: this.$route.params.campaign,
					username: this.username,
					phone: this.phone,
				};
				if (this.$store.getters.isGroup) data.group = this.group;
				await this.$store.dispatch("user/login", data);
			} catch (error) {
				console.log(error);
			}
		},
	},
	async mounted() {
		try {
			this.loading = true;
			await this.$store.dispatch(
				"activeCampaign/check",
				this.$route.params.campaign
			);
			this.loading = false;
			setCampaignMeta(this.$store.getters.campaign);
		} catch (e) {
			this.loading = false;
			this.$router.push({ name: "CampaignNotFound" });
		}
	},
};
</script>
