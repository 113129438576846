<template>
	<div class="admin-dashboard">
		<div class="progress" v-if="loading">
			<v-progress-circular
				:size="70"
				:width="7"
				color="primary"
				indeterminate
				v-if="loading"
				class="progress"
			></v-progress-circular>
		</div>
		<v-container v-else>
			<h3>משתמשים</h3>
			<div class="d-flex">
				<v-select
					v-model="select"
					hint="בחרו את הקפמיין הרצוי"
					:items="campaigns"
					item-text="cid"
					label="קמפיין"
					persistent-hint
					return-object
					single-line
				/>
				<v-btn @click="downloadExcel" class="my-3">
					<v-icon class="green--text">mdi-file-excel</v-icon>
					הורד לאקסל
				</v-btn>
			</div>
			<UsersTable :users="cUsers" />
		</v-container>
	</div>
</template>

<script>
import UsersTable from "@/components/UsersTable";
import actions from "../actions";

export default {
	name: "UsersView",
	components: {
		UsersTable,
	},
	data() {
		return {
			loading: false,
			select: null,
			cUsers: [],
		};
	},
	computed: {
		campaigns() {
			let c = this.$store.getters.campaigns;
			if (!this.select && c.length > 0) this.select = c[0];
			return c;
		},
		users() {
			return this.$store.state.users.data || [];
		},
	},
	watch: {
		select(v) {
			let t = [];
			this.users.forEach(u => {
				u.campaigns.forEach(c => {
					if (c.cid === v.cid) {
						u.cScore = c.score;
						t.push(u);
					}
				});
			});
			this.cUsers = [...t];
		},
	},
	async mounted() {
		await this.$store.dispatch("campaigns/get");
		await this.$store.dispatch("users/get");
	},
	methods: {
		async downloadExcel() {
			await this.$store.dispatch("users/get");
			let users = this.$store.getters.users;
			actions.downloadDataToExcel(users);
		},
	},
};
</script>
<style lang="scss" scoped>
.progress {
	width: 100%;
	height: 100%;
	margin: 100px auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
