<template>
	<div class="home">
		<v-container class="form-container d-flex justify-center flex-column">
			<form @keyup.enter="submit">
				<v-text-field
					v-model="username"
					label="שם משתמש"
					required
				></v-text-field>
				<v-text-field
					v-model="password"
					:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
					label="סיסמה"
					:type="show ? 'text' : 'password'"
					hint="לפחות 8 תוים"
					:rules="[rules.required, rules.min]"
					:counter="8"
					required
				>
					></v-text-field
				>
				<v-row class="justify-center">
					<v-btn @click="submit">שלח</v-btn>
				</v-row>
			</form>
			<v-alert
				v-if="isAlertMessage"
				outlined
				type="error"
				class="d-flex justify-center mt-5"
				>{{ this.error }}
			</v-alert>
			<div class="progress">
				<v-progress-circular
					:size="70"
					:width="7"
					color="primary"
					indeterminate
					v-if="loading"
				></v-progress-circular>
			</div>
		</v-container>
	</div>
</template>

<script>
export default {
	name: "Home",
	data: () => {
		return {
			show: false,
			username: null,
			password: null,
			rules: {
				required: value => !!value || "נא להזין סיסמה",
				min: v => v.length >= 8 || "לפחות 8 תווים",
				isValidForm: () => !(this.username && this.username.length > 0),
			},
			isAlertMessage: false,
			loading: false,
		};
	},
	methods: {
		async submit() {
			this.loading = true;
			try {
				await this.$store.dispatch("admin/login", {
					username: this.username,
					password: this.password,
				});
				this.$router.push({ name: "CampaignsView" });
			} catch (e) {
				console.log(e);
			}
			this.loading = false;
		},
	},
};
</script>
<style lang="scss" scoped>
.home {
	.form-container {
		width: 50%;
	}
	.progress {
		margin: 20% auto;
	}
}
</style>
