<template>
	<v-dialog
		v-model="openModal"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
		scrollable
	>
		<v-card>
			<v-toolbar flat max-height="80">
				<v-btn icon @click="close">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title v-if="isNew">יצירת קמפיין חדש</v-toolbar-title>
				<v-toolbar-title v-else>
					<span>עריכת קמפיין </span>
					<span>{{ campaign.name }}</span>
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn v-if="isNew" text @click="createCampaign">
						יצירה
					</v-btn>
					<v-btn v-else text @click="updateCampaign">
						שמירה
					</v-btn>
					<v-icon v-if="!isNew" color="red" @click="deleteCampaign"
						>mdi-delete</v-icon
					>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text class="d-flex align-start justify-center">
				<div>
					<div class="d-flex">
						<v-text-field
							:rules="nameRules"
							type="text"
							v-model="campaign.name"
							:value="campaign.name"
							label="שם הקמפיין"
						/>
						<v-switch v-model="campaign.visibleName" label="יוצג ככותרת" />
					</div>
					<v-text-field
						placeholder="מזהה קמפיין"
						v-model="campaign.cid"
						hint="המזהה צריך להיות באנגלית ללא רווחים וייחודי"
						:rules="cidRules"
						v-if="isNew"
					/>
					<p v-else>
						מזהה הקמפיין:
						<span class="font-weight-black">{{ campaign.cid }}</span>
					</p>
					<v-textarea
						type="text"
						v-model="campaign.description"
						:value="campaign.description"
						label="תיאור הקמפיין"
					/>
					<v-switch v-model="campaign.hasInfoIcon" label="אייקון מידע?" />
					<v-row v-if="campaign.hasInfoIcon" justify="space-between">
						<v-col cols="5">
							<v-file-input
								accept="image/png, image/jpeg, image/gif"
								:label="
									this.isNew ? 'העלת אייקון מידע חדש' : 'עדכון אייקון מידע'
								"
								prepend-icon="mdi-camera"
								v-model="campaign.infoIcon"
								@change="campaign.updateInfoIcon = true"
						/></v-col>
						<v-col cols="5">
							<v-text-field
								placeholder="לינק לעמוד מידע"
								v-model="campaign.infoLink"
								hint="המשתמש יופנה לקישור זה כאשר ילחץ על האייקון"
								:rules="cidRules"
								v-if="campaign.hasInfoIcon"
						/></v-col>
						<v-col cols="2">
							<img
								:src="infoIconSrc"
								width="100"
								style="margin: 0 auto"
								v-if="
									!this.isNew &&
										this.campaign.hasInfoIcon &&
										this.campaign.infoIconPath
								"
							/>
						</v-col>
					</v-row>
					<v-switch v-model="campaign.isGroup" label="משחק בקבוצות?" />
					<div class="groups" v-if="campaign.isGroup">
						<v-text-field
							type="text"
							label="הוסף קבוצה חדשה"
							@keyup.enter="addGroup"
							v-model="newGroup"
						/>
						<div class="groups-list">
							<v-chip
								class="ma-2 primary"
								v-for="(item, i) in campaign.groups"
								:key="i"
								close
								@click:close="removeGroup(i)"
							>
								{{ item }}
							</v-chip>
						</div>
					</div>
					<v-autocomplete
						v-model="campaign.font"
						:items="fonts"
						item-text="family"
						dense
						return-object
						label="גוגל פונטס"
					></v-autocomplete>
					<div>
						<label v-if="!this.isNew">רקע</label>
						<v-icon v-if="bgSrc" color="red" @click="removeBgImage"
							>mdi-delete</v-icon
						>
						<img
							:src="bgSrc"
							width="100"
							style="margin: 0 auto"
							v-if="!this.isNew && this.campaign.backgroundImagePath"
							id="bgThumb"
						/>
						<v-file-input
							accept="image/png, image/jpeg, image/gif"
							:label="this.isNew ? 'העלת רקע חדש' : 'עדכון רקע קמפיין'"
							prepend-icon="mdi-camera"
							v-model="campaign.backgroundImage"
							@change="campaign.updateBg = true"
						/>
					</div>
					<div>
						<label v-if="!this.isNew">אייקון</label>
						<v-icon v-if="imgSrc" color="red" @click="removeImage"
							>mdi-delete</v-icon
						>
						<img
							:src="imgSrc"
							width="100"
							style="margin: 0 auto"
							v-if="!this.isNew && this.campaign.imagePath"
							id="imgThumb"
						/>
						<v-file-input
							accept="image/png, image/jpeg, image/gif"
							:label="this.isNew ? 'העלת אייקון חדש' : 'עדכון אייקון קמפיין'"
							prepend-icon="mdi-camera"
							v-model="campaign.image"
							@change="campaign.updateImage = true"
						/>
					</div>
					<v-row justify="center">
						<v-col
							cols="3"
							class="d-flex flex-column align-center justify-center"
						>
							<label>צבע רקע</label>
							<v-color-picker
								style="margin:0 auto"
								v-model="campaign.backgroundColor"
								mode="hexa"
							></v-color-picker>
						</v-col>
						<v-col
							cols="3"
							class="d-flex flex-column align-center justify-center"
						>
							<label>צבע ראשי</label>
							<v-color-picker
								style="margin:0 auto"
								v-model="campaign.mainColor"
							></v-color-picker>
						</v-col>
						<v-col
							cols="3"
							class="d-flex flex-column align-center justify-center"
						>
							<label>צבע תפריט עליון</label>
							<v-color-picker
								style="margin:0 auto"
								v-model="campaign.menuColor"
								mode="hexa"
							></v-color-picker>
						</v-col>
						<v-col
							cols="3"
							class="d-flex flex-column align-center justify-center"
						>
							<label>צבע מסגרת</label>
							<v-color-picker
								style="margin:0 auto"
								v-model="campaign.frameColor"
							></v-color-picker>
						</v-col>
					</v-row>
					<div v-if="!isNew">
						<label>משחקים</label>
						<v-simple-table>
							<template v-slot:default>
								<thead>
									<tr>
										<th>שם המשחק</th>
										<th>תיאור</th>
										<th>נוצר בתאריך</th>
										<th>סוג</th>
										<th>קישור למשחק</th>
										<th>קוד משחק</th>
										<th>משחק פעיל</th>
										<th>פעולות</th>
										<th class="text-left">
											<v-btn
												fab
												small
												color="primary"
												@click="createGameDialog = true"
											>
												<v-icon>mdi-plus</v-icon>
											</v-btn>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="game in games" :key="game._id">
										<td>{{ game.name }}</td>
										<td>{{ game.description }}</td>
										<td>{{ game.createdAt }}</td>
										<td>{{ game.type }}</td>
										<td>{{ game.link }}</td>
										<td>{{ game.gameCode }}</td>
										<td>{{ game.isActive }}</td>
										<td>
											<v-icon @click="updateSingleGame(game)"
												>mdi-pencil</v-icon
											>
											<v-icon @click="deleteSingleGame(game)" class="red--text"
												>mdi-delete</v-icon
											>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</div>
				</div>
			</v-card-text>
		</v-card>
		<GameClassHandler
			v-model="createGameDialog"
			:campaign="campaign"
			:isNew="true"
		/>
		<GameClassHandler
			v-model="updateGameDialog"
			:campaign="campaign"
			:isNew="false"
		/>
		<v-snackbar
			class=" d-flex justify-center text-center"
			v-model="snackbar"
			color="primary"
		>
			<div class="text-center">{{ errorMsg }}</div>
		</v-snackbar>
	</v-dialog>
</template>

<script>
import GameClassHandler from "../gameClass/GameClassHandler";

export default {
	name: "CampaignHandler",
	components: { GameClassHandler },
	props: {
		isNew: Boolean,
		value: Boolean,
		chosenCampaign: Object,
	},
	data() {
		return {
			snackbar: null,
			errorMsg: null,
			alertDialog: false,
			createGameDialog: false,
			updateGameDialog: false,
			colorPickerDialog: true,
			backgroundColor: null,
			newGroup: "",
			imgSrc: null,
			bgSrc: null,
			infoIconSrc: null,
			nameRules: [v => !!v || "נא למלא שדה זה"],
			cidRules: [
				v => !!v || "נא למלא שדה זה",
				v => /^[a-zA-Z]+$/.test(v) || "נא להזין אותיות באנגלית ללא רווחים",
			],
			font: "",
			chosenGame: {},
			campaign: {
				hasInfoIcon: false,
				visibleName: true,
				isActive: true,
				groups: [],
			},
		};
	},
	watch: {
		openModal(val) {
			if (val === false) this.clean();
			else {
				if (this.isNew) this.clean();
				else {
					this.campaign = { ...this.chosenCampaign };
					this.font = this.campaign.font ? this.campaign.font : "";
				}
			}
		},
		campaign(val) {
			let base = "/uploads/campaigns/";
			if (val.imagePath) this.imgSrc = base + val.imagePath;
			if (val.infoIconPath) this.infoIconSrc = base + val.infoIconPath;
			if (val.backgroundImagePath) this.bgSrc = base + val.backgroundImagePath;
		},
		"campaign.backgroundColor"(val) {
			if (typeof val === "object") this.campaign.backgroundColor = val.hex;
		},
		"campaign.mainColor"(val) {
			if (typeof val === "object") this.campaign.mainColor = val.hex;
		},
		"campaign.menuColor"(val) {
			if (typeof val === "object") this.campaign.menuColor = val.hex;
		},
		"campaign.frameColor"(val) {
			if (typeof val === "object") this.campaign.frameColor = val.hex;
		},
	},
	computed: {
		openModal: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		games: {
			get() {
				return this.isNew ? {} : this.$store.state.games.campaignGames;
			},
			set(val) {
				return val;
			},
		},
		formValidator() {
			return (
				(this.campaign.name &&
					this.campaign.cid &&
					this.campaign.font &&
					/^[a-zA-Z]+$/.test(this.campaign.cid)) ||
				false
				// this.campaign.hasInfoIcon
				// 	? this.campaign.infoIcon && this.campaign.infoLink
				// 	: true && /^[a-zA-Z]+$/.test(this.campaign.cid)) || false
			);
		},
		fonts() {
			return this.$store.getters.google;
		},
	},
	methods: {
		async createCampaign() {
			if (!this.formValidator) {
				return this.logError("נא למלא את כל השדות ולבחור צבעים");
			}
			this.$emit("closeCampaignHandler");
			await this.$store.dispatch("campaigns/insert", this.campaign);
			this.clean();
		},
		async updateCampaign() {
			if (!this.formValidator) {
				return this.logError("נא למלא את כל השדות ולבחור צבעים");
			}
			try {
				this.$emit("closeCampaignHandler");
				await this.$store.dispatch("campaign/update", this.campaign);
				this.clean();
			} catch (e) {
				console.log(e);
			}
		},
		async deleteCampaign() {
			this.$swal({
				icon: "warning",
				title: '<p style="direction:rtl;">למחוק קמפיין לצמיתות?</p>',
				html: "<p>עם הקמפיין ימחקו כל המשתמשים וכל המשחקים הקשורים אליו</p>",
				showConfirmButton: true,
				showCancelButton: true,
				confirmButtonText: "אישור",
				cancelButtonText: "ביטול",
			}).then(async result => {
				if (result.isConfirmed) {
					await this.$store.dispatch("campaigns/delete", this.campaign._id);
					this.$emit("closeCampaignHandler");
					this.clean();
				}
			});
		},
		updateSingleGame(game) {
			this.$store.commit("setGame", { ...game, campaignId: this.campaign._id });
			this.updateGameDialog = true;
		},
		deleteSingleGame(game) {
			this.$swal({
				icon: "warning",
				title: '<p style="direction:rtl;">למחוק משחק לצמיתות?</p>',
				showConfirmButton: true,
				showCancelButton: true,
				confirmButtonText: "אישור",
				cancelButtonText: "ביטול",
			}).then(async result => {
				if (result.isConfirmed) {
					this.$store.dispatch("games/delete", game._id);
					this.$store.commit("setCampaignGames", this.campaign._id);
				}
			});
		},
		logError(msg) {
			this.errorMsg = msg;
			this.snackbar = true;
		},
		removeImage() {
			delete this.campaign.image;
			this.imgSrc = null;
			this.campaign.updateImage = true;
		},
		removeBgImage() {
			delete this.campaign.backgroundImage;
			this.bgSrc = null;
			this.campaign.updateBg = true;
		},
		clean() {
			this.campaign = {
				visibleName: true,
				isActive: true,
				groups: [],
			};
		},
		close() {
			this.clean();
			this.$emit("closeCampaignHandler");
		},
		selectFont(val) {
			if (val) {
				this.campaign.fontUrl = val.files.regular;
				this.campaign.isGoogle = true;
				this.campaign.fontFamily = val.family;
			}
		},
		addGroup() {
			if (this.newGroup) {
				const found = this.campaign.groups.find(
					group => group === this.newGroup
				);
				!found && this.campaign.groups.push(this.newGroup);
			}
			this.newGroup = "";
		},
		removeGroup(i) {
			this.campaign.groups = this.campaign.groups.filter(
				(item, idx) => idx !== i
			);
		},
	},
};
</script>

<style scoped></style>
