import axios from "axios";
import router from "@/router/index";

export default {
	state: {
		data: {},
	},
	getters: {
		user: state => state.data,
	},
	mutations: {
		"user/set": (state, payload) => (state.data = payload),
		"user/setScore": (state, { cid, score }) => {
			console.log(cid, score, "FROM ");
			state.data.campaigns.forEach(c => {
				console.log(c);
				if (c.cid === cid) c.score = score;
			});
			console.log(state.data);
		},
		"user/remove": state => (state.data = {}),
	},
	actions: {
		"user/login": async (context, payload) => {
			let { data } = await axios.post("/api/auth/login", payload);
			context.commit("user/set", data);
			router.push({
				name: "CampaignDashboard",
				params: { campaign: payload.cid },
			});
		},
		"user/logout": async (context, cid) => {
			await axios.get("/api/auth/logout");
			context.commit("user/remove");
			router.push(`/${cid}`);
		},
		"user/checkLogin": async (context, cid) => {
			try {
				let { data } = await axios.get(`/api/auth/checkLogin/${cid}`);
				context.commit("user/set", data);
			} catch (error) {
				throw new Error("unauthorized");
			}
		},
	},
};
