<template>
	<div class="admin-dashboard">
		<div class="progress" v-if="loading">
			<v-progress-circular
				:size="70"
				:width="7"
				color="primary"
				indeterminate
				v-if="loading"
				class="progress"
			></v-progress-circular>
		</div>
		<v-container v-else class="d-flex align-center flex-column">
			<h2>קמפיינים</h2>
			<CampaignsContainer />
			<v-btn
				@click="campaignHandlerDialog = true"
				class="primary add-campaign-btn"
				fab
			>
				<v-icon>mdi-briefcase-plus</v-icon>
			</v-btn>
			<CampaignHandler
				v-model="campaignHandlerDialog"
				:isNew="true"
				@closeCampaignHandler="closeCampaignHandler"
			/>
		</v-container>
	</div>
</template>

<script>
// import CampaignsContainer from "@/components/campaigns/CampaignsContainer";
// import CampaignCreator from "@/components/campaigns/CampaignCreator";
import CampaignsContainer from "@/components/campaigns/CampaignsContainer";
import CampaignHandler from "@/components/campaigns/CampaignHandler";

export default {
	name: "Campaigns",
	data() {
		return {
			loading: true,
			campaignHandlerDialog: false,
		};
	},
	methods: {
		closeCampaignHandler() {
			this.campaignHandlerDialog = false;
		},
	},
	components: { CampaignsContainer, CampaignHandler },
	async mounted() {
		await this.$store.dispatch("campaigns/get");
		await this.$store.dispatch("games/get");
		this.loading = false;
	},
};
</script>
<style lang="scss" scoped>
.progress {
	width: 100%;
	height: 100%;
	margin: 100px auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.add-campaign-btn {
	position: fixed;
	bottom: 20px;
	left: 20px;
	z-index: 100;
}
</style>
