import axios from "axios";
export default {
	state: {
		data: {},
		games: [],
		winners: [],
		groupWinners: [],
		groups: [],
		isGroup: false,
	},
	getters: {
		campaign: state => state.data,
		games: state => state.games,
		groups: state => state.groups,
		isGroup: state => state.isGroup,
		groupWinners: state => state.groupWinners,
	},
	mutations: {
		"activeCampaign/setCampaign": (state, payload) => (state.data = payload),
		"activeCampaign/setGames": (state, { games, user }) => {
			games.forEach(game => {
				game.isPlayed = false;
				user.playedGames.forEach(playGame => {
					if (game._id === playGame) game.isPlayed = true;
				});
			});
			state.games = games;
		},
		"activeCampaign/setWinners": (state, payload) => {
			payload.forEach(w => {
				let cid = state.data.cid;
				w.campaigns.forEach(wc => {
					if (wc.cid === cid) {
						w.cScore = wc.score;
						if (wc.group) w.cGroup = wc.group;
					}
				});
			});
			state.winners = payload;
		},
		"activeCampaign/setGroupWinners": (state, payload) => {
			let cid = state.data.cid;
			const groupWinners = [];
			state.groups.forEach(group => {
				let groupScore = 0;
				let groupMembersCount = 0;
				payload.forEach(winner => {
					winner.campaigns.forEach(wc => {
						if (wc.cid === cid && wc.group && wc.group === group) {
							groupScore += wc.score;
							groupMembersCount++;
						}
					});
				});
				const avg =
					groupScore && groupMembersCount ? groupScore / groupMembersCount : 0;
				groupWinners.push({ name: group, score: avg });
			});
			state.groupWinners = groupWinners;
		},
		"activeCampaign/groups": (state, payload) => {
			state.groups = payload.groups;
			state.isGroup = payload.isGroup;
		},
	},
	actions: {
		"activeCampaign/check": async (context, cid) => {
			try {
				let { data } = await axios.get(`/api/campaigns/check/${cid}`);
				context.commit("activeCampaign/setCampaign", data);
				context.commit("activeCampaign/groups", data);
			} catch (e) {
				throw new Error(e);
			}
		},
		"activeCampaign/get": async (context, cid) => {
			try {
				let { data } = await axios.get(`/api/campaigns/${cid}`);
				context.commit("activeCampaign/setCampaign", data.campaign);
				context.commit("activeCampaign/setGames", {
					games: data.games,
					user: data.user,
				});
				if (data.campaign.isGroup) {
					context.commit("activeCampaign/groups", {
						isGroup: true,
						groups: data.campaign.groups,
					});
				}
			} catch (e) {
				console.log(e);
			}
		},
		"activeCampaign/checkAccessCode": async (context, payload) => {
			try {
				await axios.post(
					`/api/game/${context.state.data.cid}/checkAccessCode`,
					{ gameId: payload.gameId, accessCode: payload.accessCode }
				);
			} catch (e) {
				throw e.response.data;
			}
		},
		"activeCampaign/endGame": async (context, payload) => {
			try {
				let cid = context.state.data.cid;
				console.log(cid);
				let { data } = await axios.post(`/api/game/${cid}/endGame`, {
					endCode: payload.endCode,
					gameId: payload.gameId,
				});
				await context.dispatch("user/checkLogin", cid);
				await context.dispatch("activeCampaign/get", cid);
			} catch (e) {
				throw e.response.data;
			}
		},
		"activeCampaign/checkLinear": async (context, { gameId }) => {
			try {
				await axios.post(`/api/game/${context.state.data._id}/checkLinear`, {
					gameId,
				});
			} catch (e) {
				throw e.response.data;
			}
		},
		"activeCampaign/winners": async (context, cid) => {
			try {
				const { data } = await axios.get("/api/campaigns/winners/" + cid);
				context.commit("activeCampaign/setWinners", data);
				context.commit("activeCampaign/setGroupWinners", data);
			} catch (e) {
				console.log(e);
			}
		},
		"activeCampaign/groups": async (context, cid) => {
			try {
				let { data } = await axios.get(`/api/campaigns/getGroups/${cid}`);
				context.commit("activeCampaign/groups", data);
			} catch (e) {
				console.log(e);
				throw e.response.data;
			}
		},
	},
};
